<template>
    <div class="tabs">
        <template v-if="$resize && $mq.above(1201)">
            <router-link class="title-tab" :class="{active: activeTab == 'users'}" :to="{ name: 'reports', query: {type: 'users'}}">{{ $t('reports.users') }}</router-link>
            <a class="title-tab" :href="`${organigram_fe_link}/reports?type=users`">{{ $t('reports.responsibilities') }}</a>
            <a class="title-tab" :href="`${organigram_fe_link}/reports?type=activities`">{{ $t('reports.activities') }}</a>
            <router-link class="title-tab" :class="{active: activeTab == 'processes'}" :to="{ name: 'reports', query: {type: 'processes'}}">{{ $t('reports.processes') }}</router-link>
            <router-link class="title-tab" :class="{active: activeTab == 'procedures'}" :to="{ name: 'reports', query: {type: 'procedures'}}">{{ $t('reports.procedures') }}</router-link>
            <router-link class="title-tab" :class="{active: activeTab == 'improvements'}" :to="{ name: 'reports', query: {type: 'improvements'}}">{{ $t('reports.improvement_requests') }}</router-link>
            <a class="title-tab" :href="`${objectives_fe_link}/reports`">{{ $t('reports.promises') }}</a>
        </template>
        <div v-if="$resize && $mq.below(1200)" class="actions-dropdown">
            <div class="filter-dropdown dropdown">
                <button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span class="text" v-if="activeTab == 'users'">{{$t('reports.users') }}</span>
                    <span class="text" v-else-if="activeTab == 'processes'">{{$t('reports.processes') }}</span>
                    <span class="text" v-else-if="activeTab == 'procedures'">{{$t('reports.procedures') }}</span>
                    <span class="text" v-else-if="activeTab == 'improvements'">{{$t('reports.improvement_requests') }}</span>
                    <div class="icon-filter"><icon-arrow /></div>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownFilter">
                    <router-link class="dropdown-item" :to="{ name: 'reports', query: {type: 'users'}}">
                        <div class="checkbox">
                            <span class="text">{{ $t('reports.users') }}</span>
                        </div>
                    </router-link>
                    <a class="dropdown-item" :href="`${organigram_fe_link}/reports?type=users`">
                        <div class="checkbox">
                            <span class="text">{{ $t('reports.responsibilities') }}</span>
                        </div>
                    </a>
                    <a class="dropdown-item" :href="`${organigram_fe_link}/reports?type=activities`">
                        <div class="checkbox">
                            <span class="text">{{ $t('reports.activities') }}</span>
                        </div>
                    </a>
                    <router-link class="dropdown-item" :to="{ name: 'reports', query: {type: 'processes'}}">
                        <div class="checkbox">
                            <span class="text">{{ $t('reports.processes') }}</span>
                        </div>
                    </router-link>
                    <router-link class="dropdown-item" :to="{ name: 'reports', query: {type: 'procedures'}}">
                        <div class="checkbox">
                            <span class="text">{{ $t('reports.procedures') }}</span>
                        </div>
                    </router-link>
                    <router-link class="dropdown-item" :to="{ name: 'reports', query: {type: 'improvements'}}">
                        <div class="checkbox">
                            <span class="text">{{ $t('reports.improvement_requests') }}</span>
                        </div>
                    </router-link>
                    <a class="dropdown-item" :href="`${objectives_fe_link}/reports`">
                        <div class="checkbox">
                            <span class="text">{{ $t('reports.promises') }}</span>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import IconArrow from '../../Icons/Arrow'

    export default {
    	components: {
            IconArrow
        },
        props: {
            activeTab: String
        },
        data() {
            return {
                organigram_fe_link: process.env.VUE_APP_ORGANIGRAM_FE_LINK,
                objectives_fe_link: process.env.VUE_APP_OBJECTIVE_FE_LINK
            }
        },
        mounted(){
        },
        methods: {
        }
    }
</script>